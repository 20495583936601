import React from "react";
import "./style.css";

export const Home = () => {
  return (
    <div className="HOME">
      <div className="div">
        <div className="overlap-group">
          <img className="bg-ellipse" alt="Bg ellipse" src="/img/bg-ellipse.svg" />
          <div className="shadow-ellipse" />
          <div className="down">
            <img className="img" alt="Arrow down" src="/img/arrow-down.svg" />
            <div className="scroll-down">SCROLL DOWN</div>
          </div>
          <div className="headline">
            <p className="gain-a-competitive">Gain a competitive edge at Amazon&#39;s marketplace</p>
            <p className="our-software-for">
              Our software for marketplace sellers is a true treasure trove of possibilities.&nbsp;&nbsp;Let&#39;s make
              your marketplace business more profitable and efficient together.
            </p>
          </div>
          <img className="logo" alt="Logo" src="/img/logo-6-1.svg" />
        </div>
        <div className="shadow-ellipse-2" />
        <img className="bg-pattern" alt="Bg pattern" src="/img/bg-pattern-2.png" />
        <img className="bg-pattern-2" alt="Bg pattern" src="/img/bg-pattern-1.png" />
        <div className="copy">
          <p className="text-wrapper">SIA LAB INC. All rights reserved</p>
          <img className="copyright" alt="Copyright" src="/img/copyright.svg" />
        </div>
        <div className="contacts">
          <div className="text">
            <div className="start-your-project">Start Your Project Now!</div>
            <p className="p">Get in Touch with Us Today!</p>
          </div>
          <div className="div-2">
            <div className="div-3">
              <img className="img-2" alt="Ic outline email" src="/img/ic-outline-email.svg" />
              <input className="email-sialabs" placeholder="sales@sialabs.com" type="email" />
            </div>
            <div className="div-3">
              <img className="img-2" alt="Bi phone" src="/img/bi-phone.svg" />
              <div className="text-wrapper-2">(209) 555-0104</div>
            </div>
          </div>
        </div>
        <div className="about-us">
          <div className="tip">
            <div className="text-wrapper-3">About Us</div>
          </div>
          <div className="flexcontainer">
            <p className="text-2">
              <span className="span">SIA LAB</span>
              <span className="text-wrapper-4">
                {" "}
                — is a team of software development experts specializing in creating innovative solutions for
                marketplace sellers.
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-4">
                We believe in the powerful impact of technology on the business landscape, and that&#39;s why we are
                dedicated to crafting products that help our clients thrive and flourish&nbsp;&nbsp; in the world of
                e-commerce.
              </span>
            </p>
          </div>
        </div>
        <div className="services">
          <div className="services-2">
            <div className="div-4">
              <img className="img-3" alt="Add fill" src="/img/add-fill.svg" />
              <div className="text-wrapper-5">Replenishment</div>
            </div>
            <div className="div-4">
              <img className="img-3" alt="Analytics sharp" src="/img/analytics-sharp.svg" />
              <div className="text-wrapper-5">Analytics</div>
            </div>
          </div>
          <div className="div-4">
            <div className="text-wrapper-6">And more</div>
          </div>
        </div>
        <div className="service">
          <img className="img" alt="Soft unwrap" src="/img/soft-unwrap.svg" />
          <div className="div-2">
            <div className="warehouse-management">Warehouse Management System</div>
            <p className="text-wrapper-7">
              for efficient inventory control, order fulfillment, and overall warehouse optimization
            </p>
          </div>
        </div>
        <div className="headline-2">
          <div className="our-services-wrapper">
            <div className="text-wrapper-3">Our Services</div>
          </div>
          <div className="software">Software</div>
        </div>
      </div>
    </div>
  );
};
